import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
import CardSlider from './sliders/CardSlider';
import FloatingBg from './animations/FloatingBg';

var initiateCardSlider = function initiateCardSlider() {
  var cardSliders = document.querySelectorAll('.card-slider');

  if (cardSliders.length > 0) {
    cardSliders.forEach(function (slider) {
      var prevTrigger = slider.querySelector('.card-slider__prev');
      var nextTrigger = slider.querySelector('.card-slider__next');
      var newSlider = new CardSlider(slider, 'card-slider', nextTrigger, prevTrigger);
      newSlider.init();
    });
  }
};

var initiateFloatingBackgrounds = function initiateFloatingBackgrounds() {
  var background = document.querySelector('.floats');

  if (background) {
    var newBackground = new FloatingBg(background, background.querySelectorAll('.floats__source'));
    newBackground.init();
  }
};

window.addEventListener('DOMContentLoaded', function () {
  initiateCardSlider();
  initiateFloatingBackgrounds();
});